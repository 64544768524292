import { useCallback, useEffect, useMemo, useRef, useState, ReactNode } from 'react'
import moment from 'moment-timezone'
import { capitalize, isEmpty } from 'lodash'
import DateTimePicker from '@ui-components-3/ui/lib/components/DateTimePicker'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import { SCHEDULING_DATE_FORMAT, SCHEDULING_TIME_FORMAT, extractDate, extractTime, extractTimes } from 'util/scheduling'
import TestIds from 'util/TestIds'
import useId from '@ui-components-3/ui/lib/hooks/useId'

/* date-fns and moment have different tokens */
export const SCHEDULING_DATE_FORMAT2 = 'EEE MM/d/yyyy'
const RADIO_CARD_WIDTH = 300

const parseTimes = (times: string[]) =>
  (times || []).map((_time) => ({
    time: _time,
  }))

type SchedulingInputProps = {
  'data-testid': string
  timesByDate: { [key: string]: string[] }
  dateLabel: string
  timeLabel: string
  onChange: (datetime: string | null) => void
  clearScheduledAt: () => void
  timezone: string
  error?: string
  times?: string[]
  value?: string
  id?: number
  children: ReactNode
}

const SchedulingInput = (props: SchedulingInputProps) => {
  const { onChange, timezone, timesByDate, value, clearScheduledAt, children } = props
  const [date, setDate] = useState(() => extractDate(value, timezone, timesByDate))
  const [time, setTime] = useState(() => extractTime(value, timezone))
  const [times, setTimes] = useState(() => parseTimes(extractTimes(timesByDate, date)))
  const timesListRef = useRef<HTMLDivElement>(null)

  const radioGroupLabelId = useId()

  const dataTestId = props['data-testid']

  const handleDateChange = useCallback(
    (newDate: any) => {
      if (date !== newDate) {
        clearScheduledAt?.()
        setTime('')
        setDate(newDate)
        setTimes(parseTimes(timesByDate[newDate]))
        timesListRef?.current?.scrollTo?.({
          behavior: 'smooth',
          top: 0,
        })
      }
    },
    [setDate, timesByDate, date, clearScheduledAt],
  )

  const isDateDisabled = useCallback(
    (day: string) => {
      const str = moment(day).format(SCHEDULING_DATE_FORMAT)
      return isEmpty(timesByDate[str])
    },
    [timesByDate],
  )

  useEffect(() => {
    handleDateChange(extractDate(value, timezone, timesByDate))
  }, [timesByDate])

  useEffect(() => {
    if (date && time) {
      const datetime = `${date} ${time}`
      const newDate = moment(datetime, `${SCHEDULING_DATE_FORMAT} ${SCHEDULING_TIME_FORMAT}`)
        .tz(timezone)
        .startOf('minute')
        .toISOString()
      if (value !== newDate) {
        onChange(newDate)
      }
    }
  }, [date, time, timezone, value])

  useEffect(() => {
    if (!times || (time && !times.find((item) => item.time === time))) {
      onChange(null)
      setTime('')
    }
  }, [times])

  const displayDate = useMemo(() => moment(date).format('MMMM Do, YYYY'), [date])

  return (
    <div className="grid grid-cols-1 items-start justify-center gap-x-5 gap-y-5 xl:grid-cols-[420px_1fr] 2xl:gap-x-8">
      <div className="flex items-center justify-center sm:h-[420px] sm:border sm:border-neutral-300 sm:p-4 lg:p-6">
        <DateTimePicker
          className="typography-body !w-full !max-w-[420px]"
          disablePast
          isDateDisabled={isDateDisabled}
          data-testid={dataTestId}
          onChange={handleDateChange}
          inline
          transformBeforeChange={(v: string, formatFn: (value: string, format: string) => void) =>
            formatFn(v, SCHEDULING_DATE_FORMAT2)
          }
          value={new Date(date as string)}
        />
      </div>

      <div className=" flex max-h-[420px] flex-col">
        {/* The only way to tell if a date is "Invalid Date" is to cast at Date then check if it is NAN */}
        {isNaN(moment(date).toDate().getTime()) ? null : (
          <div id={radioGroupLabelId} className="typography-body-xl mb-4 font-bold text-neutral-800">
            Available time for {displayDate}
          </div>
        )}
        {children}
        <div className="flex-1 overflow-y-auto" ref={timesListRef}>
          <RadioCardsGroup
            options={times}
            name="scheduledAtTime"
            aria-labelledby={radioGroupLabelId}
            value={time}
            onChange={(e) => setTime(e.target.value)}
            getOptionValue={(_time) => _time.time}
            getOptionLabel={(_time) => (
              <div className="typography-body-l text-primary-600 py-1 font-medium">{_time.time}</div>
            )}
            getLabelProps={(_time) => ({
              className: 'bg-white',
              'data-testid': TestIds.newVisit.button.slotTime(times.indexOf(_time)),
            })}
            columnWidth={RADIO_CARD_WIDTH}
          />
        </div>
      </div>
    </div>
  )
}

export default SchedulingInput
